import lazyImport from "../helpers/lazyImport";
import { CustomRouteProps } from "../helpers/Routes";
import LayoutNoAuth from "../components/commons/LayoutNoAuth";
import LayoutAuth from "../components/commons/LayoutAuth";

const ConsumptionMeasurement = lazyImport(
	import("../pages/ConsumptionMeasurement")
);
const ConsumptionMeasurementDetail = lazyImport(
	import("../pages/ConsumptionMeasurementDetail")
);
const Contacts = lazyImport(import("../pages/Contacts"));
const Documents = lazyImport(import("../pages/Documents"));
const Home = lazyImport(import("../pages/Home"));
const Login = lazyImport(import("../pages/Login"));
const NotFound = lazyImport(import("../pages/NotFound"));
const Payments = lazyImport(import("../pages/Payments"));
const Statistics = lazyImport(import("../pages/Statistics"));

export const freeRoutes: CustomRouteProps[] = [
	{ exact: true, path: "/", layout: LayoutNoAuth, section: Home },
	{ exact: true, path: "**", layout: LayoutNoAuth, section: NotFound },
];

export const noAuthRoutes: CustomRouteProps[] = [
	{ exact: true, path: "/login", layout: LayoutNoAuth, section: Login },
];

export const authRoutes: CustomRouteProps[] = [
	{
		exact: true,
		path: "/consumption-measurement",
		layout: LayoutAuth,
		section: ConsumptionMeasurement,
	},
	{
		exact: true,
		path: "/consumption-measurement/detail/:border",
		layout: LayoutAuth,
		section: ConsumptionMeasurementDetail,
	},
	{ exact: true, path: "/contacts", layout: LayoutAuth, section: Contacts },
	{ exact: true, path: "/documents", layout: LayoutAuth, section: Documents },
	{ exact: true, path: "/payments", layout: LayoutAuth, section: Payments },
	{ exact: true, path: "/statistics", layout: LayoutAuth, section: Statistics },
];
