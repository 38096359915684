import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "./components/Snackbar";
import ThemeWrapper from "./components/ThemeWrapper";
import { makeRoutes } from "./helpers/Routes";
import { authRoutes, freeRoutes, noAuthRoutes } from "./routes/allRoutes";
import "./App.scss";
import { SELECTOR_SESSION } from "./store/auth/selectors";

function ScrollToTop() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return null;
}

function App() {
	const session = useSelector(SELECTOR_SESSION);

	return (
		<Router>
			<ThemeWrapper>
				<Snackbar>
					<ScrollToTop />
					<React.Suspense fallback={<span>loading...</span>}>
						<Switch>
							{!session.isAuthenticate && makeRoutes(noAuthRoutes)}
							{session.isAuthenticate && makeRoutes(authRoutes)}
							{makeRoutes(freeRoutes)}
						</Switch>
					</React.Suspense>
				</Snackbar>
			</ThemeWrapper>
		</Router>
	);
}

export default App;
