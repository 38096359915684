/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

interface ILayoutAuth {
	children: React.ReactChildren;
}

function LayoutAuth({ children }: ILayoutAuth) {
	return (
		<React.Fragment>
			<Header />
			{children}
			<Footer />
		</React.Fragment>
	);
}

export default LayoutAuth;
