import React from "react";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const COLOR_PRIMARY = "rgba(13, 161, 74, 1)";
export const COLOR_PRIMARY_CONTRAST = "rgba(255, 255, 255, 1)";
export const COLOR_SECONDARY = "rgba(20, 125, 205, 1)";
export const COLOR_SECONDARY_CONTRAST = "rgba(255, 255, 255, 1)";

const theme = createTheme({
	palette: {
		primary: {
			main: COLOR_PRIMARY,
			contrastText: COLOR_PRIMARY_CONTRAST,
		},
		secondary: {
			main: COLOR_SECONDARY,
			contrastText: COLOR_SECONDARY_CONTRAST,
		},
	},
	typography: {
		fontFamily: "'Montserrat', sans-serif",
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					color: COLOR_SECONDARY,
					"&:hover": {
						color: COLOR_SECONDARY,
					},
				},
			},
		},
	},
});

const useGlobalStyles = makeStyles({
	"@global": {
		".w-100": {
			width: "100%",
		},
	},
});

function GlobalStyles(props: any) {
	useGlobalStyles();
	return <React.Fragment>{props.children}</React.Fragment>;
}

export interface ThemeWrapperProps {
	children: JSX.Element | Array<JSX.Element>;
}

export default function ThemeWrapper(props: ThemeWrapperProps) {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles>{props.children}</GlobalStyles>
		</ThemeProvider>
	);
}
