import { ServiceSocket } from "@bahoque/client-service-feathers";
import feathers from "@feathersjs/feathers";
import socketIo from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";
import { ACCESS_TOKEN, HOST_API, PROTOCOL_WS, STORAGE } from "../constants";

const socketClient = io(`${PROTOCOL_WS}://${HOST_API}`, {
	transports: ["websocket"],
});

const client = feathers();

client.configure(socketIo(socketClient));
client.configure(
	auth({
		path: "/authentication",
		jwtStrategy: "jwt",
		storageKey: ACCESS_TOKEN,
		storage: STORAGE,
	})
);

ServiceSocket.client = client;

export default ServiceSocket.client!;
