import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import { UserRead } from "../../connections/dto/User";

export interface InitialStateAuth {
	isAuthenticate: boolean;
	user: UserRead | null;
}

const INITIAL_STATE: InitialStateAuth = {
	isAuthenticate: false,
	user: null,
};

const { Types, Creators } = createActions({
	setSession: ["user"],
	delSession: [],
});

const setSession = (
	state: InitialStateAuth,
	{ user }: Pick<InitialStateAuth, "user">
) => {
	return produce(state, (draftState) => {
		draftState.isAuthenticate = true;
		draftState.user = user;
	});
};

const delSession = (state: InitialStateAuth) => {
	return produce(state, (draftState) => {
		draftState.isAuthenticate = false;
		draftState.user = null;
	});
};

export const auth = createReducer<any>(INITIAL_STATE, {
	[Types.SET_SESSION]: setSession,
	[Types.DEL_SESSION]: delSession,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default { Types, Creators };
