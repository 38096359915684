// const HOST_MATCH = [
// 	/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
// 	/^'192\.168\.1\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
// ];
//
// export const IS_LOCAL = Boolean(
// 	window.location.hostname === "localhost" ||
// 		window.location.hostname === "[::1]" ||
// 		window.location.hostname.match(HOST_MATCH[0]) ||
// 		window.location.hostname.match(HOST_MATCH[1])
// );

export const IS_LOCAL = false;

export const TIMEOUT = 60000;

export const HOST_API_REMOTE = "clientes-api.gecelca.com.co";
export const HOST_API_LOCAL = "localhost:3030";
export const HOST_API = IS_LOCAL ? HOST_API_LOCAL : HOST_API_REMOTE;
export const PROTOCOL_WS = IS_LOCAL ? "ws" : "wss";
export const PROTOCOL_HTTP = IS_LOCAL ? "http" : "https";

export const S3_HOST = "https://web-app-clientes.s3.us-east-1.amazonaws.com";

export const ACCESS_TOKEN = "feathers-jwt";
export const STORAGE = window.localStorage;
