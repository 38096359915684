import React from "react";
import { Route, RouteProps } from "react-router-dom";

export interface CustomRouteProps extends RouteProps {
	layout: React.ElementType;
	section: React.ElementType;
}

export const makeRoutes = (arr: CustomRouteProps[]) =>
	arr.map(({ section: Section, layout: Layout, ...props }, i) => (
		<Route
			key={i}
			{...props}
			render={(routeProps) => (
				<Layout {...routeProps}>
					<Section {...routeProps} {...props} />
				</Layout>
			)}
		/>
	));
