import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";

interface ProviderProps {
	children?: React.ReactChild | React.ReactChild[];
}

function Provider(props: ProviderProps) {
	return (
		<SnackbarProvider
			maxSnack={5}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
		>
			{props.children}
		</SnackbarProvider>
	);
}

interface ConnectServiceProps {
	children?: React.ReactChild | React.ReactChild[];
}

function ConnectService(props: ConnectServiceProps) {
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		// ServiceSocket.errorHook = (message: string) => {
		// 	enqueueSnackbar(message, { variant: "error" });
		// };
	}, [enqueueSnackbar]);

	return <React.Fragment>{props.children}</React.Fragment>;
}

interface SnackbarProps {
	children?: React.ReactChild | React.ReactChild[];
}

function Snackbar(props: SnackbarProps) {
	return (
		<Provider>
			<ConnectService>{props.children}</ConnectService>
		</Provider>
	);
}

export default Snackbar;
