import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { SELECTOR_UI_TITLE } from "../../store/ui/selectors";
import { SELECTOR_SESSION } from "../../store/auth/selectors";
import { Dropdown } from "antd";
import { Box, Link } from "@mui/material";
import Card from "./Card";
import { logout } from "../../store/auth/actions";
import { S3_HOST } from "../../constants";

export const HEIGHT = "86px";

const useStyles = makeStyles<Theme, any>((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		height: HEIGHT,
	},
	navigation: ({ isAuthenticate }) => {
		const background = isAuthenticate
			? "background-header-2.svg"
			: "background-header.svg";
		return {
			display: "flex",
			alignItems: "center",
			justifyContent: isAuthenticate ? "flex-end" : "flex-start",
			minWidth: 800,
			[isAuthenticate ? "paddingRight" : "paddingLeft"]: 150,
			backgroundImage: `url("https://web-app-rse.s3.us-east-1.amazonaws.com/public/${background}")`,
			backgroundRepeat: "no-repeat",
			backgroundPositionY: "bottom",
			backgroundPositionX: isAuthenticate ? "left" : "right",
			color: "white",
			lineHeight: 1,
		};
	},
	contentLogo: ({ isAuthenticate }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		[isAuthenticate ? "paddingLeft" : "paddingRight"]: 150,
		height: HEIGHT,
		"& img": {
			height: 66,
		},
	}),
	title: {
		color: "white",
		fontSize: 40,
		fontWeight: 700,
		lineHeight: 1,
	},
	nav: {
		display: "flex",
		justifyContent: "space-between",
		margin: 0,
		padding: 0,
		marginLeft: 100,
		listStyle: "none",
		"& > li": {
			display: "flex",
			alignItems: "center",
			marginLeft: 30,
			marginRight: 30,
			height: `calc(${HEIGHT} - 10px)`,
			borderTop: "5px solid transparent",
			borderBottom: "5px solid transparent",
			fontSize: 14,
			fontWeight: 700,
			textAlign: "center",
			cursor: "pointer",
			"&.active": {
				borderBottom: "5px solid #ffffff",
			},
		},
	},
	userMenuContent: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
}));

interface ItemMenuProps {
	label: React.ReactNode;
	section: string;
}

function ItemMenu(props: ItemMenuProps) {
	const { label, section } = props;

	const match = useRouteMatch<{ section: string }>(`/${section}`);
	const history = useHistory();

	return (
		<li
			className={clsx({ active: match })}
			onClick={() => history.push(`/${section}`)}
		>
			{label}
		</li>
	);
}

function Header() {
	const session = useSelector(SELECTOR_SESSION);

	const title = useSelector(SELECTOR_UI_TITLE);

	const classes = useStyles({ isAuthenticate: session.isAuthenticate });
	const dispatch = useDispatch<any>();
	const history = useHistory();

	const menu = (
		<Card className={classes.userMenuContent}>
			<Link
				onClick={() => {
					dispatch(logout()).then(() => {
						history.push("/login");
					});
				}}
				display='flex'
			>
				<Box mr={2}>
					<img src={`${S3_HOST}/icons/logout.svg`} alt="logout" />
				</Box>
				Cerrar sesión
			</Link>
		</Card>
	);

	return (
		<div className={clsx(classes.root)}>
			{!session.isAuthenticate && (
				<div className={clsx(classes.navigation)}>
					<div className={clsx(classes.title)}>{title}</div>
				</div>
			)}
			<div className={clsx(classes.contentLogo)}>
				<a href="https://www.gecelca.com.co/">
					<img
						src="https://web-app-rse.s3.us-east-1.amazonaws.com/public/logotype.png"
						alt="Logo"
					/>
				</a>
			</div>
			{session.isAuthenticate && (
				<div className={clsx(classes.navigation)}>
					<ul className={clsx(classes.nav)}>
						<ItemMenu label={"Facturación"} section={"payments"} />
						<ItemMenu
							label={"Medición de Consumo"}
							section={"consumption-measurement"}
						/>
						<ItemMenu label={"Directorio de contacto"} section={"contacts"} />
						<ItemMenu label={"Documentos"} section={"documents"} />
						<ItemMenu label={"Evolución tarifa"} section={"statistics"} />
						<Dropdown overlay={menu}>
							<li>
								<Box mr={2}>
									<img src={`${S3_HOST}/icons/profile-user.svg`} alt="logout" />
								</Box>
								{session?.user?.first_name} {session?.user?.last_name}
								<Box ml={2}>
									<img src={`${S3_HOST}/icons/arrow-down.svg`} alt="logout" />
								</Box>
							</li>
						</Dropdown>
					</ul>
				</div>
			)}
		</div>
	);
}

export default Header;
