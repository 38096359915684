import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

export const HEIGHT = "220px";

const useStyles = makeStyles(() => ({
	root: {
		position: "absolute",
		bottom: 0,
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
		width: "100%",
		height: HEIGHT,
		backgroundImage:
			'url("https://web-app-rse.s3.us-east-1.amazonaws.com/public/background-footer%402x.png")',
		backgroundSize: "cover",
		zIndex: -1,
	},
	copy: {
		color: "white",
		fontWeight: "bold",
		marginBottom: 26,
	},
}));

function Footer() {
	const classes = useStyles();

	return (
		<>
			<Box height={HEIGHT} />
			<div className={clsx(classes.root)}>
				<div className={classes.copy}>
					Gecelca © Todos los derechos reservados. Desarrollado por e-me
				</div>
			</div>
		</>
	);
}

export default Footer;
