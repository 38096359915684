import { combineReducers } from "redux";
import { State } from "./State";

import { auth } from "./auth/reducer";
import { ui } from "./ui/reducer";

export default combineReducers<State>({
	auth,
	ui,
});
