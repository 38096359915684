import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

export interface InitialStateUI {
	title: string;
}

const INITIAL_STATE: InitialStateUI = {
	title: "GECELCA: Clientes",
};

const { Types, Creators } = createActions({
	setTitle: ["title"],
});

const setTitle = (state: InitialStateUI, { title }: InitialStateUI) => {
	return produce(state, (draftState) => {
		draftState.title = title;
	});
};

export const ui = createReducer<any>(INITIAL_STATE, {
	[Types.SET_TITLE]: setTitle,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default { Types, Creators };
