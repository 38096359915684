/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { reAuthenticate } from "../../store/auth/actions";
import { authRoutes } from "../../routes/allRoutes";

interface ILayoutNoAuth {
	children: React.ReactChildren;
}

function LayoutNoAuth({ children }: ILayoutNoAuth) {
	const dispatch = useDispatch<any>();
	const history = useHistory();
	const match = useRouteMatch<{ section: string }>("/:section");

	useEffect(() => {
		dispatch(reAuthenticate())
			.then(() => {
				const path = `/${match?.params.section || ""}`;
				if (!authRoutes.map((it) => it.path).filter((it) => it === path).length)
					history.push("/payments");
			})
			.catch(() => {
				history.push("/login");
			});
	}, []);

	return (
		<React.Fragment>
			<Header />
			{children}
			<Footer />
		</React.Fragment>
	);
}

export default LayoutNoAuth;
