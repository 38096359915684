import React from "react";

export default function lazyImport(
	moduleImport: Promise<any>,
	moduleName: string = "default"
): React.LazyExoticComponent<React.ComponentType<any>> {
	return React.lazy(() =>
		moduleImport.then((module) => ({ default: module[moduleName] }))
	);
}
