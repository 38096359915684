import { Dispatch } from "redux";
import authReducer from "./reducer";
import {
	sendAuthenticationCode as sendCode,
	authenticate as authenticateService,
	reAuthenticate as reAuthenticateService,
	logout as logoutService,
	users,
} from "../../connections/services";
import { STORAGE } from "../../constants";

function authResponseHandle(req: Promise<any>): Promise<any> {
	return req.catch((error) => {
		if (error?.code === 200) {
			return error;
		}

		throw error;
	});
}

export function sendAuthenticationCode(email: string) {
	return () => {
		return authResponseHandle(sendCode(email));
	};
}

export function authenticate(email: string, otp_email: string) {
	return (dispatch: Dispatch<any>) => {
		return authResponseHandle(authenticateService(email, otp_email)).then(
			(res) => {
				dispatch(authReducer.Creators.setSession(res.user));
				return res;
			}
		);
	};
}

export function reAuthenticate() {
	return (dispatch: Dispatch<any>) => {
		return reAuthenticateService()
			.then(async (res) => {
				const user = await users.get(res.user.id).toPromise();
				dispatch(authReducer.Creators.setSession(user));
				return res;
			})
			.catch((err) => {
				dispatch(authReducer.Creators.delSession());
				throw err;
			});
	};
}

export function logout() {
	return (dispatch: Dispatch<any>) => {
		return authResponseHandle(logoutService()).then((res) => {
			STORAGE.clear();
			dispatch(authReducer.Creators.delSession());
			return res;
		});
	};
}
