import React from "react";
import MuiCard, { CardProps } from "@mui/material/Card";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	card: {
		borderRadius: 8,
	},
});

function Card({ className, children, ...props }: CardProps) {
	const classes = useStyles();
	return (
		<MuiCard elevation={2} className={clsx(classes.card, className)} {...props}>
			{children}
		</MuiCard>
	);
}

export default Card;
