import { ServiceSocket, TListen } from "@bahoque/client-service-feathers";
import client from "./connections";
import { CompanyRead } from "./dto/Company";
import { ConsumptionMetricRead } from "./dto/ConsumptionMetrics";
import { ContactRead } from "./dto/Contact";
import { ContactCategoryRead } from "./dto/ContactCategory";
import { DocumentCategoryRead } from "./dto/DocumentCategory";
import { DocumentCompanyRead } from "./dto/DocumentCompany";
import { InvoiceRead } from "./dto/Invoice";
import { PricingHistoryRead } from "./dto/PricingHistory";
import { UserBorderRead } from "./dto/UserBorder";
import { UserCompanyRead } from "./dto/UserCompany";
import { UserRead } from "./dto/User";

const defaultListen: TListen = {
	created: true,
	patched: true,
	updated: true,
	removed: true,
};

export const companies = new ServiceSocket<CompanyRead>(
	"companies",
	defaultListen
);
export const consumptionMetric = new ServiceSocket<ConsumptionMetricRead>(
	"consumption-metrics",
	defaultListen
);
export const consumptionMetricMatrixExcel = new ServiceSocket<{ fileUrl: string }>(
	"consumption-metrics/matrix-excel",
	defaultListen
)
export const contacts = new ServiceSocket<ContactRead>(
	"contacts",
	defaultListen
);
export const contactsCategories = new ServiceSocket<ContactCategoryRead>(
	"contacts-categories",
	defaultListen
);
export const documentsCategories = new ServiceSocket<DocumentCategoryRead>(
	"documents-categories",
	defaultListen
);
export const documentsCompanies = new ServiceSocket<DocumentCompanyRead>(
	"documents-companies",
	defaultListen
);
export const invoices = new ServiceSocket<InvoiceRead>(
	"invoices",
	defaultListen
);
export const pricingHistory = new ServiceSocket<PricingHistoryRead>(
	"pricing-history",
	defaultListen
);
export const usersBorders = new ServiceSocket<UserBorderRead>(
	"user-borders",
	defaultListen
);
export const usersCompanies = new ServiceSocket<UserCompanyRead>(
	"user-companies",
	defaultListen
);
export const users = new ServiceSocket<UserRead>("users", defaultListen);

export const sendAuthenticationCode = (email: string) => {
	return client.authenticate({ strategy: "email", email });
};

export const authenticate = (email: string, otp_email: string) => {
	return client.authenticate({ strategy: "email", email, otp_email });
};

export const reAuthenticate = () => client.reAuthenticate();

export const logout = () => client.logout();
